import * as React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import {
  Box,
  Button,
  Container,
  Grid,
  SvgIcon,
  Typography,
} from '@mui/material';
import errorSvg from '../images/404lostinspace.svg';
// styles
const style: SxStyle = {
  ContainerGrid: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  ErrorTextGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  SvgGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  SvgIcon: {
    width: '400px',
    height: '400px',
    padding: '40px',
  },
};

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not Found">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2} sx={style.ContainerGrid}>
            <Grid xs={6} sx={style.ErrorTextGrid}>
              <Typography variant="h1">404</Typography>
              <Typography variant="h2">
                Die Seite nach der du sucht Exestiert nicht.
              </Typography>
              <Button variant="contained" onClick={() => navigate(`/`)}>
                Zur Startseite
              </Button>
            </Grid>
            <Grid xs={6} sx={style.SvgGrid}>
              <SvgIcon
                component={errorSvg}
                sx={style.SvgIcon}
                viewBox="0 0 400 400"
              ></SvgIcon>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
